import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { UserSettingService } from '@services/profile/usersetting.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  // State of the filter drawer
  isFilterDrawerCollapsed = true;

  // Default tab
  tabIndex = 1;

  tabOrder = ['STORIES', 'POSTS', 'ANGLES', 'GROUPS', 'PEOPLE'];

  // Search term entered in search box
  searchTerm: string = '';

  private subs = new SubSink();

  // Search HTML input box
  @ViewChild('searchInputField') searchInputField!: ElementRef;

  constructor(
    private location: Location,
    private gaService: GoogleAnalyticsEventService,
    private searchService: GlobalSearchService,
    private activatedRoute: ActivatedRoute,
    private userSettingService: UserSettingService,
    private breakpointService: BreakpointService,
  ) {

    this.isFilterDrawerCollapsed = this.isMobile;

  }

  ngOnInit() {

    this.searchService.clearFilters();
    this.searchTerm = '';

    // When page loads, if there is a search term passed in the URL, populate the search service
    this.subs.sink = this.activatedRoute.queryParams.subscribe(param => {

      if (param.tab) {

        this.setInitTab(param.tab);

      }
      if (param.searchString) {

        this.setInitSearchString(param.searchString);

      }

      console.log('Search Term:', this.searchTerm);

    });

    // If the search term changes by the Search Service, update the search text in the input box
    this.subs.sink = this.searchService.searchTerm.subscribe((searchText: string) => {

      this.gaService.trackGlobalSearch(searchText);
      this.searchTerm = searchText;

    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * When changes are made in the metadata/sidebar filter drawer,
   * broadcast the change to all child components through the Search Service
   *
   */
  applyFilters() {

    this.searchService.filterChanged.next();

  }

  /**
   * Change Tab
   *
   */
  onChangeTab(event: NzTabChangeEvent) {

    this.tabIndex = event.index;

    this.updateURL();

  }

  /**
   * When search term is entered in input field
   *
   */
  setSearchTerm(event: any) {

    const text = (event.target.value || '').trim();

    this.searchService.searchTerm.next(text);

    this.updateURL();

  }

  /**
   * Clear Search Term
   *
   */
  clearSearchTerm() {

    this.searchService.clearFilters();

    this.searchService.searchTerm.next('');

    this.searchInputField.nativeElement.focus();

    this.updateURL();

  }

  /**
   * If a tab is passed in the URL
   *
   */
  setInitTab(tab: string) {

    const tabs = this.tabOrder.map((key: string) => key.toLowerCase());

    if (tabs.includes(tab.toLowerCase())) {

      this.tabIndex = tabs.indexOf(tab);

    }

  }

  /**
   * If a search term is passed in the URL
   *
   */
  setInitSearchString(keyword: string) {

    this.searchService.searchTerm.next(keyword);
    this.searchTerm = keyword;

  }

  /**
   * Toggle filter drawer
   *
   */
  toggleFilterDrawer(): void {

    this.isFilterDrawerCollapsed = !this.isFilterDrawerCollapsed;

  }

  /**
   * Close filter drawer
   *
   */
  closeFilterDrawer() {

    this.isFilterDrawerCollapsed = true;

  }

  /**
   * Update the URL with the tab and search string
   *
   */
  updateURL() {

    const hash = window.location.hash;

    const query = hash.split('?');

    let params;

    if (query && query.length) {

      params = new URLSearchParams(query[1]);

    } else {

      params = new URLSearchParams();

    }

    params.set('tab', this.tabOrder[this.tabIndex].toLowerCase());

    if (this.searchTerm) {

      params.set('searchString', this.searchTerm);

    } else {

      params.delete('searchString');

    }

    const queryString = decodeURIComponent(params.toString());

    this.location.replaceState(`/ncx/search-results?${queryString}`);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
