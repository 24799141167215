import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { AngleMode, AngleTitle } from '@models/ncx/angle';
import { Stories } from '@models/ncx/story';
import { CommonService } from '@services/common-service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { SubSink } from 'subsink';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-story-angles',
  templateUrl: './story-angles.component.html',
  styleUrls: ['../story-landing-tabs-common.scss', './story-angles.component.scss'],
})
export class StoryAnglesComponent implements OnChanges, OnInit, OnDestroy {

  isLoaded: boolean = true;

  // API results for story Angles
  data: AngleTitle[] = [];

  // Currently loaded data
  page: number = 1;

  total: number = 0;

  limit: number = 10;

  // Do we need to load additional items to engage the scrollbar?
  initLoadingMoreForScroll: boolean = false;

  // Story information
  @Input() storyId: number = 0;

  @Input() storyDetails: Stories = {} as Stories;

  // Filters from parent component
  @Input() filters!: UntypedFormGroup;

  // Is this tab visible to the user?
  @Input() isVisible: boolean = false;

  // Height of the footer for viewport calculations
  @Input() footerHeight: number = 0;

  // Parent controls the opening/closing of filter drawer
  @Input() filterDrawerOpen: boolean = false;

  // Tell parent component when body is scrolling
  @Output() isScrolling: EventEmitter<void> = new EventEmitter<void>();

  // Tell parent when filter drawer should open/close
  @Output() toggleFilterDrawer: EventEmitter<void> = new EventEmitter<void>();

  private subs = new SubSink();

  constructor(
    private cService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {

    this.subs.sink = this.filters.valueChanges.pipe(debounceTime(500)).subscribe(() => {

      this.loadAngles();

    });

    // Get the story Id from the URL and load required APIs
    this.subs.sink = this.activatedRoute.params.subscribe((params: Params) => {

      this.storyId = params.storyId.substr(1);

      if (this.storyId && !isNaN(this.storyId)) {

        this.loadAngles();

      }

    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  ngOnChanges(changes: SimpleChanges) {

    // Reload data whenever the tab in the parent component has changed
    if ('isVisible' in changes && changes.isVisible.currentValue === true) {
      this.loadAngles();
    }

    // Story Id has changed, so reload the angles
    if ('storyId' in changes && changes.storyId.currentValue > 0) {
      this.loadAngles();
    }

  }

  /**
   * [Enter] is clicked on Search bar
   *
   */
  onSearch() {

    this.loadAngles();

  }

  /**
   * Clear Search
   *
   */
  clearSearch() {

    this.filters.patchValue({ search: '' });

  }

  /**
   * Scroll Down Event call when page is scroll down
   * Fetch the next set of records
   */
  onLoadMore() {

    this.page += 1;

    this.loadAngles();

  }

  /**
   * Scroll event to change "sticky" mode of header
   *
   */
  scrollContainer() {

    this.isScrolling.emit();

  }

  /**
   * Fetch the Angles from API based on payload
   */
  loadAngles() {

    this.isLoaded = false;

    const filters = this.filters.getRawValue();

    const { authorSSO, sort, search, privacy } = filters;

    const payload = {
      authorSSO: authorSSO,
      angleAccess: privacy,
      contentPage: this.page,
      endDate: 0,
      searchString: search,
      sortField: sort === 'relevancescore' ? 'relevancescore' : 'modificationDate',
      sortFieldType: sort === 'relevancescore' ? 'xs:string' : 'xs:long',
      sortOrder: sort === 'relevancescore' ? 'descending' : sort,
      startDate: 0,
      storyId: Number(this.storyId),
    };

    // const payLoad = this.createPayLoad();
    this.cService.serviceRequestCommon('post', environment.getAngleSearch, '', payload).subscribe((res: any) => {

      // Data from API
      const data: AngleTitle[] = res?.angles || [];

      // New Angles to add to list
      const angles: AngleTitle[] = [];

      if (Array.isArray(data)) {

        data.forEach((angle: AngleTitle) => {

          const angleDetail: AngleTitle = {} as AngleTitle;

          angleDetail.angleId = angle.angleId;
          angleDetail.angleTitle = angle.angleTitle;
          angleDetail.angleTitleSnippet = angle.angleTitleSnippet;
          angleDetail.createdDateTime = angle.createdDateTime;
          angleDetail.createdUser = angle.createdUser;
          angleDetail.privacy = angle.angleAccess.toLowerCase() == AngleMode.Public ? false : true;
          angles.push(angleDetail);

        });

        this.data = this.page === 1 ? [...angles] : [...this.data, ...angles];

        this.total = res.totalCount || 0;

      }

    }).add(() => {

      this.isLoaded = true;

      // Do this whenever we're at Page 1 in order to fill the viewport with scrollable content
      if (this.page === 1) {

        setTimeout(() => {

          this.initLoadingMoreForScroll = false;
          this.checkIfShouldLoadMore();

        }, 1000);

      }

    });

  }

  /**
   * If there isn't enough content in the viewport to allow the user to scroll, call the onLoadMore
   *  function to force more content to load automatically.
   *
   */
  checkIfShouldLoadMore() {

    if (this.initLoadingMoreForScroll) {

      return;

    }

    this.initLoadingMoreForScroll = true;

    const storyContainer = document.getElementById('storyContainer');

    const scrollableContainer = document.querySelector('[data-scrollable-container]');

    if (!storyContainer || !scrollableContainer) {

      return;

    }

    const container = storyContainer.getBoundingClientRect();

    const scrollable = scrollableContainer.getBoundingClientRect();

    if (scrollable.height < container.height && this.page * this.limit < this.total) {

      console.log('Should Load More', { container: container.height, scrollable: scrollable.height });
      this.onLoadMore();
      this.initLoadingMoreForScroll = true;

    }

  }

  /**
   * Open/Close Filter Drawer
   *
   */
  onToggleFilterDrawer() {

    this.toggleFilterDrawer.emit();

  }

  /**
   * Can user scroll to more records, based on number of total records
   * and the current page they're on
   *
   */
  get canScrollMore(): boolean {

    return this.total > 0 && this.total > (this.page + this.limit);

  }

  /**
   * Are filters in place?
   *
   */
  get isFiltering(): boolean {

    const filters = this.filters.getRawValue();

    return filters.authorSSO || filters.sort !== 'descending';

  }

  get isSearching(): boolean {

    return !!this.filters?.get('search')?.value;

  }

}
