<div
   class="ncx-post-body"
   data-component="shared/cards/post/search">
   <!-- POST TITLE -->
   <div class="post-title">
      <!-- both href and click events are necessary in order
          for click events to work in ios-shell app -->
      <a
         class="title"
         [href]="post.targetURL"
         (click)="onViewPost()"
         [innerHTML]="(post.snippet?.title || post.title || '').trim() | safeHtml"></a>

      <!-- both href and click events are necessary in order
          for click events to work in ios-shell app -->
      <a
         class="icon"
         [href]="post.targetURL"
         (click)="onViewPost()">
         <app-svg-icon
            icon="external-link"
            size="16"></app-svg-icon>
      </a>
   </div>

   <!-- WHO CREATED/UPDATED THE POST -->
   <div class="created-updated">
      <button
         nz-popover
         [nzPopoverContent]="profileCreatedCard"
         nzPopoverPlacement="topLeft">
         {{ Common.formatName(post.createUser, true) }}
      </button>

      {{ date }}

      <ng-template #profileCreatedCard>
         <app-profile-overlay [profileData]="post.createUser"></app-profile-overlay>
      </ng-template>
   </div>

   <!-- DESCRIPTION -->
   <div
      class="description"
      *ngIf="!!post.snippet?.description?.trim() || !!post.description?.trim()"
      [innerHTML]="(post.snippet?.description || post.description || '').trim() | safeHtml"></div>

   <div
      class="description"
      *ngIf="post.snippet.description === ''"
      [innerHTML]="post.description"></div>


   <!-- ATTACHMENTS -->
   <app-view-attachments
      *ngIf="post.attachments && post.attachments.length"
      class="attachments"
      [storyId]="post.storyId"
      [postAttachments]="post.attachments"></app-view-attachments>

   <!-- EDITORIAL TAGS -->
   <app-post-labels
      class="labels"
      [editorial]="post.filters"
      [legal]="post.restrictions"></app-post-labels>

   <!-- STORY/DISCUSSION TITLE -->
   <!-- both href and click events are necessary in order
        for click events to work in ios-shell app -->
   <div class="story-discussion-title">
      <a
         (click)="onViewStory()"
         [href]="post.storyURL"
         nz-tooltip
         nzTooltipPlacement="bottomLeft"
         [nzTooltipMouseEnterDelay]="0.5"
         [nzTooltipTitle]="post.storyTitle">
         {{ post.storyTitle }}
      </a>
   </div>
</div>

<div class="ncx-post-footer">
   <div class="left-side">
      <!-- NUMBER OF COMMENTS -->
      <!-- <app-info-comments
      class="info"
      [total]="post.repliesCount"></app-info-comments> -->

      <!-- NUMBER OF ATTACHMENTS -->
      <app-info-attachments
         class="info"
         [total]="post.attachments?.length"></app-info-attachments>

      <!-- SHARE BUTTON -->
      <app-info-share
         class="info"
         (click)="onShare()"></app-info-share>
   </div>
</div>
