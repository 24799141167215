import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LightboxAttachment } from '@models/ncx/lightbox';
import { CSSThemeService } from '@services/css-theme.service';
import { UrlRedirectService } from '@services/url-redirect.service';
@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LightBoxComponent implements OnInit {

  @Input() lightboxAttachments: LightboxAttachment[] = [];

  @Input() isStoryLanding = false;

  @Input() indexToOpenOnLoad = 0;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  isVisible = true;

  currentIndex: number = 0;

  currentAttachment = {
    fileUrl: '',
    extension: '',
    name: '',
    isImage: false,
    isVideo: false,
    unsupport: false
  };

  unSupportFile = /flv|avi|mpeg|wmv|asf/;

  isLoading: boolean = false;

  isLightMode = false;

  @ViewChild('vid') vid: ElementRef;

  constructor(
    private themeService: CSSThemeService, private urlRedirectService: UrlRedirectService,
  ) { }

  ngOnInit() {

    this.currentIndex = this.indexToOpenOnLoad;

    this.isLightMode = this.themeService.currentTheme !== 'dark';

    this.loadContent();

  }

  isUnSupported: boolean;

  loadContent() {

    const attachment: LightboxAttachment = this.lightboxAttachments[this.currentIndex];
    let unSupported = !!attachment.extension.match(this.unSupportFile);

    if (!unSupported) {
      this.isLoading = true;
      this.currentAttachment = {
        fileUrl: attachment.fileUrl,
        extension: attachment.extension,
        name: attachment.attachmentName,
        isImage: attachment.isImage,
        isVideo: attachment.isVideo,
        unsupport: false
      };
      console.log('LightBox (Load Attachment)', { attachment: this.currentAttachment });
    }
    else {
      this.handleLightBoxCancel();
      window.open(this.urlRedirectService.getRedirectURL(attachment.fileUrl), '_blank');
    }
  }

  contentLoaded() {

    this.isLoading = false;

  }


  /**
   * Go to next attachment
   *
   */
  next() {

    this.isLoading = false;

    this.currentIndex = this.currentIndex === this.lightboxAttachments.length - 1 ? 0 : this.currentIndex + 1;
    const attachmentData: LightboxAttachment = this.lightboxAttachments[this.currentIndex];

    this.isUnSupported = !!attachmentData.extension.match(this.unSupportFile);
    if (this.isUnSupported) {

      this.currentAttachment = {
        fileUrl: '',
        extension: '',
        name: '',
        isImage: false,
        isVideo: false,
        unsupport: true
      };
      setTimeout(() => {

        this.next();

      }, 1000)

    } else {

      this.loadContent()

    }

  }

  /**
   * Go to previous attachment
   *
   */
  pre() {

    this.isLoading = false;

    this.currentIndex = this.currentIndex === 0 ? this.lightboxAttachments.length - 1 : this.currentIndex - 1;
    const attachmentData: LightboxAttachment = this.lightboxAttachments[this.currentIndex];

    this.isUnSupported = !!attachmentData.extension.match(this.unSupportFile);
    if (this.isUnSupported) {

      this.currentAttachment = {
        fileUrl: '',
        extension: '',
        name: '',
        isImage: false,
        isVideo: false,
        unsupport: true
      };
      setTimeout(() => {

        this.pre();

      }, 1000)

    } else {

      this.loadContent()

    }

  }

  /**
   * Close lightbox
   *
   */
  handleLightBoxCancel(): void {

    try {

      this.isVisible = false;

      this.modalClosed.emit('true');

      if (this.currentAttachment.isVideo && this.vid.nativeElement) {

        this.vid.nativeElement.pause();

      }

    } catch (error) {

      console.error('Lightbox: ', { error });

    }

  }

  /**
   * Callback after an image is loaded
   *
   * Make sure the image appears in the browser, based
   * on whether its width or height is larger
   *
   */
  onLoadImage(event: any) {

    this.isLoading = false;

    try {

      const img = event.target;

      const bounds = img.getBoundingClientRect();

      img.classList.add('show');

      console.log('LightBox (Image Loaded)', { bounds, width: img.naturalWidth, height: img.naturalHeight });

    } catch (error) {

      console.error('Lightbox', { error });

    }

  }

  /**
   * Callback after a video is loaded
   *
   */
  onLoadVideo(event: any) {

    this.isLoading = false;

    try {

      const vid = event.target;

      const bounds = vid.getBoundingClientRect();

      console.log('LightBox (Video Loaded)', { bounds });

    } catch (error) {

      console.error('Lightbox', { error });

    }

  }

}
