import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { IFunctionAbility } from '@models/users';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-move-post-modal',
  templateUrl: './move-post.component.html',
  styleUrls: ['./move-post.component.scss']

  // see styles/ng-zorro/nz-modal.scss for global styles
})
export class MovePostModalComponent implements OnInit, OnChanges {

  @Input() isMovePostVisible;

  @Input() StoryList;

  @Input() sourcePostId;

  @Input() isPinnedOnTop;

  @Output() closeMovePost: EventEmitter<any> = new EventEmitter<any>();

  @Output() updatedStories: EventEmitter<any> = new EventEmitter<any>();

  destinationStory = '';

  value = '';

  movePostApi;

  getStoryTitlesAPI;

  listofStories = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  subject: Subject<string> = new Subject();

  currentStories: any;

  deletedRelatedStories: any = [];

  isLoaded = true;

  public functionAbility = {} as IFunctionAbility;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private tI: TransferInformationComponent
  ) { }


  ngOnInit() {

    this.movePostApi = environment.getPostApi;
    this.getStoryTitlesAPI = environment.globalSearch;
    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.subject.pipe(
      debounceTime(700),
      switchMap(searchTextValue => this.handleSearchRequest(searchTextValue))).subscribe();

  }

  ngOnChanges() {

    console.log('Related stories', this.currentStories, this.StoryList);
    this.StoryList = this.StoryList.map((story) => {

      story.isPinnedOnTop = this.isPinnedOnTop;
      return story;

    });
    this.currentStories = JSON.parse(JSON.stringify(this.StoryList));

  }

  handleSearchRequest(searchTextValue) {

    // Empty condition should be handled here because switchmap has to be executed to cancel the previous request
    // Return an observable that emits an empty array if the input is empty

    if (!searchTextValue.trim()) {
      this.value = '';
      this.filteredOptions = [];
      return of([]);
    }

    return this.getStoryTitlesList(searchTextValue).pipe(
      tap((data: any) => {
        const tempStoiesObj = data.storySearchResult.map((story) => {
          return {
            autoGeneratedStoryId: story.autoGeneratedStoryId,
            storyId: story.storyID,
            storyTitle: story.storyTitle,
            storyAccess: story.storyVisibility ? "public" : "private",
            createUserId: story.createUserId,
            isPrimary: false,
            isPinnedOnTop: false
          };
        });

        this.listofStories = JSON.parse(JSON.stringify(tempStoiesObj));
        this.filteredOptions = this.listofStories.map((story) => (story.autoGeneratedStoryId ? (story.autoGeneratedStoryId + ' | ') : '') + story.storyTitle);
        this.filteredOptionsInit = this.listofStories.map((story) => (story.autoGeneratedStoryId ? (story.autoGeneratedStoryId + ' | ') : '') + story.storyTitle);
      }),
      catchError((error) => {
        // Handle the error here
        console.error('Request error:', error);
        // Return an observable to continue the stream
        return of([]);
      }),
      finalize(() => {
        // Code to be executed regardless of success or error
        //console.log('Request completed');
      })
    )
  }

  titleMatch(value) {

    if (this.currentStories.length >= 4) {

      this.toastService.createMessage('warning', 'Maximum Share Limit has been Reached');
      this.value = '';
      return;

    }

    console.log('Titlematch', value);

    value = value.includes('|') ? value.split('|')[1].trim() : value;

    const obj = this.listofStories.filter(story => story.storyTitle === value)[0];

    const obj1 = this.currentStories.filter(story => story.storyTitle === value)[0];

    if ((!(obj === null || obj === undefined) && (obj1 === null || obj1 === undefined))) {

      if (!(this.currentStories && this.currentStories.length)) {

        obj.isPrimary = true;

      }
      this.currentStories.push(obj);
      console.log('Related stories', this.currentStories);
      setTimeout(() => {

        this.value = '';

      }, 100);

    }
    if (this.filteredOptionsInit && this.filteredOptionsInit.length) {

      this.filteredOptions = [...this.filteredOptionsInit];

    }

  }

  getStoryTitlesList(queryStr?: string) {

    let params: string;

    if (queryStr) {

      //params = `?storyTitle=${queryStr}`;
      params = `?searchString=${queryStr}&contentType=Story&startIndex=1&endIndex=50&sortField=modificationDate&sortOrder=descending`;

    } else {

      this.filteredOptions = [];
      return;

    }

    return this.cService.serviceRequestCommon('post', this.getStoryTitlesAPI, params);

  }

  createDeletedStoriesObj() {

    const stories = [];

    this.deletedRelatedStories.forEach((story) => {

      const currentStoryObj = this.currentStories.filter((obj) => obj.storyId === story.storyId)[0];

      const initialStoryObj = this.StoryList.filter((obj) => obj.storyId === story.storyId)[0];

      const deletedStoryObj = this.deletedRelatedStories.filter((obj) => obj.storyId === story.storyId)[0];

      if ((currentStoryObj === null || currentStoryObj === undefined) && !(initialStoryObj === null || initialStoryObj === undefined) &&
        (deletedStoryObj === null || deletedStoryObj === undefined)) {

        stories.push(story);

      } else if ((currentStoryObj === null || currentStoryObj === undefined) && !(initialStoryObj === null || initialStoryObj === undefined)
        && !(deletedStoryObj === null || deletedStoryObj === undefined)) {

        stories.push(deletedStoryObj);

      }

    });
    this.deletedRelatedStories = stories;
    console.log('Final Deleted Array ', this.deletedRelatedStories);

  }

  handleCancel() {

    this.value = '';
    this.filteredOptions = [];

    this.currentStories = JSON.parse(JSON.stringify(this.StoryList));
    this.closeMovePost.emit(true);

  }

  handleOk() {

    // post title and post content need to be passed
    console.log('handleOk : ', this.currentStories);
    if (!(this.currentStories && this.currentStories.length > 0)) {

      this.toastService.createMessage('warning', 'Please select at least one Story');
      return;

    }
    this.createDeletedStoriesObj();
    const queryStr = `/${this.sourcePostId}/` + 'move';

    // tslint:disable-next-line:max-line-length
    const payload = {
      postId: this.sourcePostId,
      relatedStories: this.currentStories,
      deletedRelatedStories: this.deletedRelatedStories,
      isPinnedOnTop: this.isPinnedOnTop
    };

    this.isLoaded = false;
    this.cService.serviceRequestCommon('patch', this.movePostApi, queryStr, payload).subscribe((res: any) => {

      console.log('Response of merge', res);
      this.updatedStories.emit(res.relatedStories);
      this.toastService.createMessage('success', 'Post successfully moved');
      this.closeMovePost.emit(true);

    }, () => {

      this.toastService.createMessage('error', 'Error in saving data. Please try again.');
      this.closeMovePost.emit(true);

    }).add(() => {

      this.value = '';
      this.filteredOptions = [];
      this.isLoaded = true;

    });

  }

  onChange(value: string, event: any): void {

    setTimeout(() => {

      this.subject.next(event.target.value);
    }, 200);

  }

  pinStory(storyId, isPinnedOnTop) {

    console.log('pinStory:', { stories: this.currentStories, isPinnedOnTop });

    this.isPinnedOnTop = !isPinnedOnTop;
    this.currentStories = this.currentStories.map(element => {

      element.isPinnedOnTop = this.isPinnedOnTop;
      return element;

    });

  }

  postAsPrimaryStory(storyId) {

    this.currentStories.map((element) => {

      element.isPrimary = element.storyId === storyId;

    });

  }

  deleteRelatedStories(storyindex, storyId) {

    let primaryStoryDeleted = false;

    console.log('deleteRelatedStories : ', storyindex, storyId, this.currentStories);
    this.currentStories.forEach((element, index) => {

      if (storyId === element.storyId) {

        if (element.isPrimary === true) {

          primaryStoryDeleted = true;

        }
        this.currentStories.splice(index, 1);
        this.deletedRelatedStories.push(element);

        // this.addedStories.emit(this.currentStories);

      }

    });

    // If pineed story Deleted make first story as pinned
    if (primaryStoryDeleted && this.currentStories && this.currentStories.length > 0) {

      this.currentStories[0].isPrimary = true;

    }

  }

}
